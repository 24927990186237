import React from 'react'
import { Avatar } from '../../components/avatar/avatar'
import styles from './home.module.scss'
import { SocialButtonGroup } from '../../components/socialButtonGroup/socialButtonGroup'
import { WorkExperienceCard } from '../../components/workExperienceCard/workExperienceCard'

const Home = () => {
  return (
    <section className={styles.home}>
      <Avatar />

      <h1>Mehmet Furkan Yılmaz</h1>
      <h2>Co-founder & Software Developer</h2>

      <SocialButtonGroup className={styles.socialIconGroup} socialButtonClassName={styles.socialIcon} />

      <div className={styles.workExperiences}>
        <WorkExperienceCard
          description={
            'As a Software Developer at Vennyx, I work across the entire stack—frontend, backend, and blockchain. My role is to create visually stunning and secure applications that leverage the latest in blockchain technology, ensuring both functionality and innovation.'
          }
          logoImageUrl={'/images/vennyx_logo.svg'}
          logoUrl={'https://vennyx.com'}
          logoAlt={'Vennyx'}
        />
        <WorkExperienceCard
          description={
            "As a founding partner at TetaHQ, I was instrumental in driving the development of web and mobile applications. I focused on creating user-centric designs and robust backend systems, contributing to significant projects in the defense industry. My leadership and technical skills were key to the company's success."
          }
          logoImageUrl={'/images/tetahq_logo.svg'}
          logoUrl={'https://www.linkedin.com/company/tetahq/'}
          logoAlt={'TetaHQ'}
        />
      </div>
    </section>
  )
}

export { Home }
