enum SocialType {
  EMAIL = 'email',
  LINKEDIN = 'linkedin',
  GITHUB = 'github',
  INSTAGRAM = 'instagram',
  CODERS_RANK = 'codersrank',
}

interface SocialButtonProps {
  type?: SocialType
  className?: string
}

export { SocialType }
export type { SocialButtonProps }
