const Config = {
  socialLinks: {
    instagram: 'https://www.instagram.com/mhmtfy/',
    linkedin: 'https://www.linkedin.com/in/mf-yilmaz/',
    github: 'https://github.com/mhmtfy',
    mail: 'mailto:mehmet.yilmaz@vennyx.com',
    codersRank: 'https://profile.codersrank.io/user/mhmtfy',
  },
}

export { Config }
