import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import { Home } from './pages/home/home'
import './index.module.scss'
import 'animate.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<Home />)

reportWebVitals()
